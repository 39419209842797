import React from "react";
import ProductTemplate from "./product";
import NewProductTemplate from "../../components/sections/product-more-info/Item";

const Product = props => {
    const { pageContext } = props;
    const { item } = pageContext;

    const newProductsEnabled = process.env.GATSBY_NEW_PRODUCTS_ENABLED === "true";

    const Data = () => {
        return (
            <div className="space-y-5">
                <p>
                    Preparació intensiva de les proves físiques per Mossos d'Esquadra, Guàrdia
                    urbana i policies locals.
                </p>
                <p>Vine a entrenar amb nosaltres les proves físiques!</p>
                <p>
                    Els <strong>dimecres i dissabtes</strong> de <strong>09.30</strong> a{" "}
                    <strong>11.30</strong> hores
                </p>
                <p>
                    La nostra filosofia és extreure el màxim rendiment de cada alumne amb trucs i
                    millores personalitzades.
                </p>
                <p>
                    Es buscarà la millora de{" "}
                    <strong>velocitat, potència, força i resistència</strong>.
                </p>
                <p>
                    D'altra banda, el treball específic per al{" "}
                    <strong>circuit d'agilitat, press banca i course navette</strong>.
                </p>
                <p>
                    <strong>Test setmanal</strong> per a portar un control individualitzat de cada
                    opositor.
                </p>
                <p>
                    El teu nou espai de referència i espais totalment adaptats a totes les
                    necessitats
                </p>
                <p>Carrer del Migdia, 43830 Torredembarra, Tarragona</p>
                <p>
                    Els nostres entrenaments estan dissenyats per portar-vos al vostre estat òptim.
                </p>
                <p>
                    Gran <strong>percentatge d'aprovats</strong> cada any!
                </p>
                <p>
                    <strong>Places limitades!</strong>
                </p>
                <p>
                    <strong>
                        Els packs estan limitats a 3 mesos de durada, és a dir, es poden utilitzar
                        fins a 3 mesos després de la seva compra.
                    </strong>
                </p>

                <p>
                    <strong>
                        Cada sessió s'ha de reservar i/o cancelar amb un mínim de 48 hores
                        d'antelació.
                    </strong>
                </p>
            </div>
        );
    };

    return (
        <>
            {!newProductsEnabled ? (
                <ProductTemplate item={item}>
                    <Data />
                </ProductTemplate>
            ) : (
                <NewProductTemplate
                    item={item}
                    title="Preparació intensiva de les proves físiques per a Mossos d'Esquadra, Guàrdia Urbana i policies locals ara a Tarragona."
                >
                    <Data />
                </NewProductTemplate>
            )}
        </>
    );
};

export default Product;
